import { Component, AfterViewInit, OnInit, OnDestroy, Renderer2  } from '@angular/core';
import * as SmartAssist from '../../../node_modules/zpf-smart-assist/src/smartAssist.m.js';

@Component({
  selector: 'app-smart-assist',
  templateUrl: './smart-assist.component.html',
  styleUrls: ['./smart-assist.component.scss']
})

export class SmartAssistComponent implements AfterViewInit, OnDestroy {
  
  constructor(private renderer: Renderer2) {}

  // This will be called after the component's view is initialized
  ngAfterViewInit(): void {
    this.addBodyClass();
  }

  // Add the class to the body when the component is loaded
  addBodyClass(): void {
    this.renderer.addClass(document.body, 'zs-master-style');
  }

  // Clean up by removing the class when the component is destroyed
  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'zs-master-style');
  }
}


